import { InputLabel } from '@collinsonx/design-system';
import { WarningIcon } from '@collinsonx/design-system/assets/icons';
import Heading from '@collinsonx/design-system/components/heading/Heading';
import { Box, Flex, Text } from '@collinsonx/design-system/core';
import { DatePickerInput } from '@collinsonx/design-system/date';
import { UseFormReturnType } from '@collinsonx/design-system/form';
import { ProductCategory } from '@collinsonx/utils';
import { BlockWrapper } from '@components/BlockWrapper/BlockWrapper';
import FlightInfoErrorHandling from '@components/flightInfo/availability/AvailableSlotsModal/FlightInfoErrorHandling';
import TimeInputLabel from '@components/TimeInputLabel';
import { logAction } from '@lib';
import { IconCalendar } from '@tabler/icons-react';
import { ANALYTICS_TAGS } from 'config/datadog';
import { FlightTimeRegEx } from 'config/flights';
import 'dayjs/locale/es';
import useLocale from 'hooks/useLocale';

import classes from './FlightInfo.module.css';

interface FlightInfoProps {
  flightNotFound: boolean;
  form?: UseFormReturnType<any, any>;
  loading: boolean;
  page?: string;
  productCategory?: ProductCategory;
  tags?: ANALYTICS_TAGS[];
}

export interface AvailabilitySlot {
  endDate: string;
  maxDuration: number;
  slotConfigurationId?: string;
  startDate: string;
}

export const FlightInfo = ({
  flightNotFound,
  form,
  loading,
  page = '',
  productCategory,
  tags = [],
}: FlightInfoProps) => {
  const translations = useLocale();

  const timeIsPrepopulated = FlightTimeRegEx.test(
    form?.getInputProps('flightTime').value
  );

  return (
    <BlockWrapper>
      <FlightInfoErrorHandling hasError={flightNotFound} />
      <Heading as="h2" lineHeight={1} margin={0} padding={0}>
        {translations.booking.flightDetails.title}
      </Heading>
      <Flex
        className={classes.containerFlex}
        gap={20}
        justify="space-between"
        wrap="wrap"
      >
        <Flex direction="column" gap={16}>
          <DatePickerInput
            aria-label={translations.generic.labels.flightDate}
            classNames={{ day: classes.day }}
            disabled={loading}
            label={translations.booking.flightDetails.dateInput.label}
            leftSection={<IconCalendar size="1.5rem" stroke={1.5} />}
            locale={translations.booking.flightDetails.localeValue}
            maw={400}
            minDate={new Date()}
            onClick={() => logAction(page, tags[0])}
            placeholder={
              translations.booking.flightDetails.dateInput.placeholder
            }
            style={{ textWrap: 'nowrap' }}
            w={270}
            withAsterisk
            {...form?.getInputProps('departureDate')}
          />
          {productCategory === ProductCategory.FastTrack && (
            <Flex align="top" direction="row" gap={8}>
              <Box pt={2}>
                <WarningIcon style={{ height: 21, width: 21 }} />
              </Box>
              <Text>{translations.booking.flightDetails.warning}</Text>
            </Flex>
          )}
        </Flex>
        <InputLabel
          aria-label={translations.generic.labels.flightNumber}
          disabled={loading}
          error="invalid flight number"
          label={translations.booking.flightDetails.numberInput.label}
          placeholder={
            translations.booking.flightDetails.numberInput.placeholder
          }
          w={270}
          withAsterisk
          {...form?.getInputProps('flightNumber')}
          isCapitalLetters
          onClick={() => logAction(page, tags[1])}
        />
        {(flightNotFound || timeIsPrepopulated) && (
          <TimeInputLabel
            aria-label={translations.generic.labels.flightTime}
            disabled={loading}
            error="invalid flight time"
            isCapitalLetters
            label={translations.booking.flightDetails.flightTimeInput.label}
            step={60}
            w={270}
            withAsterisk
            {...form?.getInputProps('flightTime')}
          />
        )}
      </Flex>
    </BlockWrapper>
  );
};
